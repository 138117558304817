export const dateFormat = (d) => {
    let isTrue = d !== null && d !== "";
    if(isTrue){
        const dateString = d;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    
        return formattedDate;
    }else{
  return false
    }
    
  }

  export const dateFormat1 = (d) => {
    let isTrue = d !== null && d !== "";
    if(isTrue){
        const dateString = d;
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const formattedDate = `${day.toString().padStart(2, '0')}-${month.toString().padStart(2, '0')}-${year}`;
    
        return formattedDate;
    }else{
  return false
    }
    
  }