import React, { useEffect, useState } from "react";
import "./GeneralAR.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DialogueSt } from "../../utils/style";
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import { useTheme } from "@mui/material/styles";
import ReportTables from "../ReportTable/ReportTable";
import HomeIcon from "@mui/icons-material/Home";
import axios from "axios";
// import CloseIcon from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@material-ui/styles";
import { BounceLoader } from "react-spinners";
import ComponentLoader from "../../ComponentLoader";

function GeneralAR({ head }) {
  let Navigate = useNavigate("");

  const NaviReportTab = () => {
    Navigate("/reporttable");
  };

  const location = useLocation();

  const [open, setOpen] = React.useState(false);
  const [rolee, setRolee] = React.useState("");
  const isTrue = rolee === "Investigator";
  const [token, setToken] = useState("");
  const [month, setMonth] = useState(null);
  const [assM, setAssMonth] = useState("");
  const [invis, setInvestigator] = useState();
  const [months, setMonths] = useState("");
  const [ins, setIns] = useState();
  const [state, setState] = useState("");
  const theme = useTheme();
  const [disables, setDisables] = useState(false);

  const [instiId, setInstiID] = useState(0);

  const [listDataDepat, setListDataDepat] = useState([]);
  const [listDataInsti, setListDataInsti] = useState([]);

  const [isInvesIsTrue, setInvesIsTrue] = useState("");
  const [handledepat, setHandleDepat] = useState([]);
  const [llll, setIII] = useState("");
  const [subitValid, setSubmitValid] = useState(false);
  const [monthData, setMonthData] = useState([]);

  const [InstituteId, setInstituteId] = useState();
  const [importMonth, setImportMonth] = useState();
  const [directorate, setDirectorate] = useState();
  const [loader, setLoader] = useState(true);
  const [dirId, setDirID] = useState(0);
  const [masState, setMasState] = useState({
    inIv: 0,
    inTu: 0,
    diId: 0,
  });
  const [instituteAdminMonthlyReport, setInstituteAdminMonthlyReport] =
    useState();
  const [monthlyReport, setMonthlyReport] = useState();
  const [TrnMonthlyReport, setTrnMonthlyReport] = useState();
  const [ariId, setAriId] = useState(0);
  console.log(monthlyReport);
  // const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [inM, setIndMonth] = useState(null);
  const arr = [{ 202306: "June" }, { 202307: "July" }];
  const arrs = [{ month: "June" }, { month: "July" }];

  const classes = DialogueSt();

  useEffect(() => {
    // head("Monthly Report");

    const rolee = localStorage.getItem("role");
    const ArINstitudeID = localStorage.getItem("ARIID");
    setAriId(ArINstitudeID);

    setRolee(rolee);

    const tok = localStorage.getItem("token");
    setIII(tok);
    monthList(tok);
    const investigator = localStorage.getItem("loginId");
    if (rolee === "Director") {
      const investigator = localStorage.getItem("loginId");
      setInstiID(investigator);
    } else if (rolee === "VC") {
      setInstiID(1007);
    }
    if (rolee === "Principal") {
      const instti = localStorage.getItem("loginId");
      setInstituteId(instti);
    }
    const inst = localStorage.getItem("loginId");
    setInvestigator(investigator);
    handleDirectDetails(tok, inst);

    setMasState((prev) => ({
      ...prev,
      diId: investigator,
    }));
    setToken(tok);
    setIns(inst);

    setToken(tok);

    if (tok != null && tok != undefined) {
      handleChooseMonth(tok);
    }
    const Month = localStorage.getItem("Month");
    if (Month !== null) {
      setAssMonth(Month);
      setMonths(Month);

      handleCountRender(Month, investigator, tok);
      // getInfo()
      setImportMonth(Month);
    }

    let dis = localStorage.getItem("disable");
    if (dis !== null) {
      setDisables(true);
    } else {
      setDisables(false);
    }

    let objectString = localStorage.getItem("MReport");
    const myObject = JSON.parse(objectString);
    let DirectorID = localStorage.getItem("DirectorID");
    // DirectorID
    setDirID(DirectorID);
    setMonthlyReport(myObject);
    if (objectString) {
      // setMonthlyReport(myObject);
      setState(myObject.id);
    }

    if (rolee == "Principal" || rolee == "VC") {
      handleDepat(tok, investigator);
    }
    if (rolee === "Director") {
      handleDepat(tok, investigator);
      // handleInstitute(tok, investigator);
    } else {
      handleInstitute(tok, investigator);
    }

    let invesIsTrue = localStorage.getItem("role");
    setInvesIsTrue(invesIsTrue);
  }, []);

  useEffect(() => {
    let loginId = localStorage.getItem("loginId");
    const rolee = localStorage.getItem("role");
    let obj = {};
    if (rolee === "Director") {
      const instiId = localStorage.getItem("ARIID");
      const institudeId = instiId ? instiId : masState.inTu;
      obj = {
        // InvestigatorId: parseInt(masState.inIv),
        RptMonth: parseInt(assM),
        DirectorateId: parseInt(loginId),
        InstituteId: parseInt(institudeId),
      };
    }
    if (rolee === "Investigator") {
      obj = {
        InvestigatorId: parseInt(loginId),
        RptMonth: parseInt(assM),
      };
    }

    if (rolee === "Principal") {
      obj = {
        // InvestigatorId: parseInt(masState.inIv),
        RptMonth: parseInt(assM),
        DirectorateId: 0,
        InstituteId: parseInt(masState.diId),
      };
    }

    const objectString = JSON.stringify(obj);
    localStorage.setItem("reportTableData", objectString);
  }, [assM, masState.inTu, masState.diId]);

  const ReportDownloadPageNavigate = () => {
    Navigate("/reporttableAr");
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCountRender = async (value, id, token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    setAssMonth(value);
    setMonth(parseInt(value));
    let monthYear = arr.map((item) => Object.keys(item) == value);
    let ind = monthYear.findIndex((item) => item == true);
    setIndMonth(ind);
    localStorage.setItem("Month", value);
    const d = {
      InvestigatorId: parseInt(id),
      RptMonth: parseInt(value),
      DirectorateId: 0,
    };

    if (rolee === "Principal") {
      try {
        const { data } = await axios.post(
          `${window.BASE}${window.INSTITUTEADMIN}`,
          d,
          {
            headers: h,
          }
        );

        setLoader(false);
        if (data.instituteAdminMonthlyReport?.length > 0) {
          // setMonthlyReport(data.instituteAdminMonthlyReport[0]);
          const objectString = JSON.stringify(
            data.instituteAdminMonthlyReport[0]
          );
          const hh = localStorage.getItem("MReport");
          const myObject = JSON.parse(hh);

          setMonthlyReport(myObject);
          // localStorage.setItem("MReport", objectString);
          // getInfo();
        }
      } catch (error) {
        setLoader(false);
      }
    } else {
    }
  };

  // Count Fetch API //
  const handleCount = async (value, opt, inds) => {
    const previouseMonth=opt[inds-1].text
    const selectedText = opt[inds].text;

    const storedArrayJSON = localStorage.getItem("myArrayKey");
    if (storedArrayJSON !== null) {
      const storedArray = JSON.parse(storedArrayJSON);

      let some = storedArray.some((item) => item == selectedText);
      if (some) {
        // setDisables(true);
      } else {
        let stored = [];
        storedArray.map((item) => stored.push(item));
        stored.push(selectedText);
        localStorage.setItem("myArrayKey", JSON.stringify(stored));
        // setDisables(false);
      }
    } else {
      let myArray = [selectedText];
      localStorage.setItem("myArrayKey", JSON.stringify(myArray));
    }
    // Parse the JSON string back into an array

    // let get = localStorage.getItem()

    localStorage.setItem("Mname", selectedText);
    
    localStorage.setItem("PrevMname", previouseMonth);
    // console.log(selectedText.slice(5,));
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    setAssMonth(value);
    setMonth(parseInt(value));
    let monthYear = arr.map((item) => Object.keys(item) == value);
    let ind = monthYear.findIndex((item) => item == true);
    setIndMonth(ind);
    localStorage.setItem("Month", value);
    const d = {
      InvestigatorId: parseInt(invis),
      RptMonth: parseInt(value),
    };
    localStorage.removeItem("disable");

    // if()
  };

  const submitFun = async () => {
    setDisables(true);
    localStorage.setItem("btnss", true);

    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    console.log(state);
    const d = {
      IAMRSId: state,
    };
    // investigatorId: parseInt(ins),
    //   rptMonth: parseInt(assM),
    try {
      const { data } = await axios.post(
        `${window.BASE}${window.ADMIN_REPORT_SUBMIT}`,
        d,
        { headers: h }
      );

      setLoader(false);

      if (data.result === true) {
        alert("Successfully Submitted");
        setDisables(true);
        // Disable the button after a successful submission
        localStorage.setItem("disable", true);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const handleInstitute = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );

      setLoader(false);
      //  setHandleDepat(data)
      setListDataInsti(data);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleDepat = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEDEPARTMENT}`,
        {
          headers: h,
        }
      );

      setLoader(false);
      //  setHandleDepat(data)
      setListDataDepat(data);
    } catch (error) {
      setLoader(false);
    }
  };

  // Admin,Director Count //
  const AdminDirector = async (value) => {
    setMasState((prev) => ({
      ...prev,
      inIv: value,
    }));

    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
  };

  // Report Fetch API //
  const handleChooseMonth = async (event) => {};

  const NextPage = (path) => {
    if (assM !== "") {
      Navigate(path, { state: { TrnMonthlyReport: monthlyReport } });
    } else {
      alert("Please Select Month");
    }
  };

  const getInvestigator = async (id, opt, index) => {
    setAriId(id);
    setInstituteId(id);

    const selectedText = opt[index].text;
    const instiName = localStorage.getItem("institudeNAme");
    if (instiName) {
      localStorage.removeItem("institudeNAme");
      localStorage.setItem("institudeNAme", selectedText);
    } else {
      localStorage.setItem("institudeNAme", selectedText);
    }
    const instiID = localStorage.getItem("ARIID");
    if (instiID) {
      localStorage.removeItem("ARIID");
      localStorage.setItem("ARIID", id);
    }
    else{
      localStorage.setItem("ARIID", id);
    }

    console.log(id, "im only");

    setMasState((prev) => ({
      ...prev,
      inTu: id,
    }));
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(
        `${window.BASE}${window.HANDLEINSTITUTE}${"/" + id}`,
        {
          headers: h,
        }
      );
      setLoader(false);
      setListDataInsti(data);
    } catch (error) {
      setLoader(false);
    }
  };

  const getInfoPrincipal = async () => {
    if (assM) {
      setLoader(true);
    }

    const h = {
      Authorization: `Bearer ${llll}`,
      "Content-Type": "application/json",
    };
    let d = {
      // InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
      RptMonth: assM !== "" ? parseInt(assM) : "",
      DirectorateId: dirId,
      InstituteId: masState.diId > 0 ? parseInt(masState.diId) : 0,
    };

    try {
      const { data } = await axios.post(
        `${window.BASE}${window.INSTITUTEADMIN}`,
        d,
        { headers: h }
      );

      setLoader(false);
      console.log(data.instituteAdminMonthlyReport[0]);
      if (data.instituteAdminMonthlyReport?.length > 0) {
        setSubmitValid(true);
        if (data.instituteAdminMonthlyReport[0].activeStatus === "Submitted") {
          localStorage.setItem("disable", true);
          setDisables(true);
        } else {
          setDisables(false);
          localStorage.removeItem("disable");
        }
        const di = JSON.stringify(data.instituteAdminMonthlyReport[0]);
        localStorage.setItem("getinfoDId", di);
        setMonthlyReport(data.instituteAdminMonthlyReport[0]);
        console.log(data.instituteAdminMonthlyReport[0].id);
        setState(data.instituteAdminMonthlyReport[0].id);
        const objectString = JSON.stringify(
          data.instituteAdminMonthlyReport[0]
        );
        localStorage.setItem("MReport", objectString);
      } else {
        setMonthlyReport({});
        localStorage.removeItem("MReport");
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const monthList = async (token) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
    try {
      const { data } = await axios.get(`${window.BASE}${window.MONTH_LIST}`, {
        headers: h,
      });

      setMonthData(data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  const handleDirectDetails = async (token, id) => {
    const h = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };

    try {
      const { data } = await axios.get(
        `${window.BASE}${window.INSTITUDE_ABOUT}${id}`,
        {
          headers: h,
        }
      );
    } catch (error) {}
  };

  const getInfo = async (value, opt, inds) => {
    if (assM === "") {
      alert("Please Select Month");
    } else if (ariId == 0) {
      alert("Please Select Institute");
    } else {
      const h = {
        Authorization: `Bearer ${llll}`,
        "Content-Type": "application/json",
      };
      // if (rolee === "Investigator") {
      //   getInfoInvestigator();
      // } else
      if (rolee === "Principal") {
        getInfoPrincipal();
      } else {
        let d = {
          // InvestigatorId: masState.inIv > 0 ? parseInt(masState.inIv) : 0,
          RptMonth: assM !== "" ? parseInt(assM) : "",
          DirectorateId: instiId > 0 ? parseInt(instiId) : 0,
          InstituteId: ariId > 0 ? parseInt(ariId) : 0,
        };
        try {
          const { data } = await axios.post(
            `${window.BASE}${window.INSTITUTEADMIN}`,
            d,
            { headers: h }
          );

          setLoader(false);
          // setMonthlyReport()
          if (data.instituteAdminMonthlyReport?.length > 0) {
            const di = JSON.stringify(d);
            localStorage.setItem("getinfoDId", di);
            setMonthlyReport(data.instituteAdminMonthlyReport[0]);
            console.log(data.instituteAdminMonthlyReport[0].id);
            setState(data.instituteAdminMonthlyReport[0].id);
            const objectString = JSON.stringify(
              data.instituteAdminMonthlyReport[0]
            );
            localStorage.setItem("MReport", objectString);
          } else {
            setMonthlyReport({});
            localStorage.removeItem("MReport");
          }
        } catch (error) {
          setLoader(false);
        }
      }
    }
  };

  const handleImport = async () => {
    if (assM === "") {
      alert("Please Select Month");
    } else if (ariId == 0) {
      alert("Pleae Select Institude");
    } else {
      const h = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const d = {
        InstituteId: parseInt(InstituteId),
        RptMonth: parseInt(assM),
      };
      try {
        const { data } = await axios.post(`${window.BASE}${window.IMPORT}`, d, {
          headers: h,
        });

        setLoader(false);
        if (data.result === "Success") {
          alert("successfully imported");
        }
      } catch (error) {
        setLoader(false);
      }
    }
  };
  const override = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "1000",
  };

  return (
    <div style={{ marginTop: 60 }}>
      {/* <BounceLoader color="#0e5b1e"  cssOverride={override} speedMultiplier={1}/> */}
      <ComponentLoader isLoading={loader} />
      <div
        style={{ height: "60px" }}
        className="headerr d-flex justify-content-between py-2 align-items-center"
      >
        <div className="d-flex ">
          <Link to={isTrue ? "/MasterDataFaculty" : "/MasterData"}>
            <HomeIcon
              style={{ marginTop: 12, marginLeft: 10, color: "white" }}
            />
          </Link>
          <h5 className="mt-3 mr-3">&nbsp;&nbsp;&nbsp;Filter:&nbsp;</h5>
          <div className="pr-2">
            <select
              value={assM}
              onChange={(e) => {
                // handleCountRender();
                handleCount(
                  e.target.value,
                  e.target.options,
                  e.target.selectedIndex
                );
              }}
              id="months"
              name="months"
            >
              <option value="">Select Month</option>
              {monthData?.map((item) => (
                <option value={item.monthCode}> {item.monthName} </option>
              ))}
            </select>
          </div>
        </div>

        {isInvesIsTrue === "VC" ? (
          <>
            <select
              onChange={(e) => setDirectorate(e.target.value)}
              value={directorate}
              id="months2"
              name="months"
            >
              <option value={0}>TRRI </option>
            </select>
          </>
        ) : null}

        {isInvesIsTrue === "Investigator" ? null : (
          <div className="Admin-Select">
            {isInvesIsTrue === "Director" || isInvesIsTrue === "VC" ? (
              <>
                <select
                  onChange={(e) =>
                    getInvestigator(
                      e.target.value,
                      e.target.options,
                      e.target.selectedIndex
                    )
                  }
                  value={ariId}
                  id="months"
                  name="months"
                >
                  <option value={0}>Institute</option>
                  {listDataDepat?.map((item) => (
                    <option value={item.id}>{item.instituteName}</option>
                  ))}
                </select>
              </>
            ) : null}
          </div>
        )}

        <div className="header-btn mt-2 mr-2">
          <button onClick={getInfo}>Get Info</button>
        </div>
        {rolee == "Principal" ?     <div className="header-btn" style={{ marginTop: 12 }}>
          <button onClick={handleImport}>Import</button>
        </div> : null}
     
        <div className="header-btn mt-2 mr-2">
          <button onClick={ReportDownloadPageNavigate}>Report View</button>
          {subitValid ? (
            <button
              className="mt-2 mr-2"
              id="submitBtn"
              disabled={disables ? true : false}
              onClick={submitFun}
            >
              Submit
            </button>
          ) : null}
        </div>
      </div>

      <div className="report-btn">
        <div>
          <Dialog
            // style={{ marginTop: -40 }}
            // fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            className={classes.dialogue}
            // aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title" className="Dialog_head">
              <div className="dialogueTittle">
                <div className="Dialog-head">
                  <h5>Report Download</h5>
                </div>
                <div onClick={handleClose} className="Cloase-icon">
                  <CloseIcon
                    style={{ marginBottom: "10" }}
                    className="dialogue_close_icon"
                  />
                </div>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <ReportTables />
              </DialogContentText>
            </DialogContent>
            {/* <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Print
              </Button>
            </DialogActions> */}
          </Dialog>
        </div>
      </div>

      <div class="overall_Box main-overallbox">
        <div className="box1 rounded p-2 ">
          <div className="box1_inner2 rounded p-box set-max-height">
            <h4 className="cl-e">A. Basic Information</h4>
            {/* <hr></hr> */}

            <div className="number" style={{ marginTop: -13 }}>
              <span>
                <div onClick={() => NextPage("/exsummary")}>
                  <Link style={{ color: "black" }}>Executive Summary</Link>
                </div>
              </span>
              <span></span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA1(a-e)")}>
                  <Link style={{ color: "black" }}>A1a. Institution Info </Link>
                </div>
              </span>
              <span>{monthlyReport?.a1A}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA1(d)")}>
                  <Link style={{ color: "black" }}>A1b. Land Area</Link>
                </div>
              </span>
              <span>{monthlyReport?.a1B}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA1(e)")}>
                  <Link style={{ color: "black" }}>A1c. Water Resource</Link>
                </div>
              </span>
              <span>{monthlyReport?.a1C}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA1(f)")}>
                  <Link style={{ color: "black" }}>A1d. Crops Info</Link>
                </div>
              </span>
              <span>{monthlyReport?.a1D}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA1(g)")}>
                  <Link style={{ color: "black" }}>A1e. Live Stock</Link>
                </div>
              </span>
              <span>{monthlyReport?.a1E}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA1(h)")}>
                  <Link style={{ color: "black" }}>A1f. Building Info </Link>
                </div>
              </span>
              <span>{monthlyReport?.a1F}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA2")}>
                  <Link style={{ color: "black" }}>A2. Staff Info</Link>
                </div>
              </span>
              <span>{monthlyReport?.a2}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA3")}>
                  <Link style={{ color: "black" }}>A3. Permanent Staff</Link>
                </div>
              </span>
              <span>{monthlyReport?.a3}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA4")}>
                  <Link style={{ color: "black" }}>A4. Leave Info</Link>
                </div>
              </span>
              <span>{monthlyReport?.a4}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA5")}>
                  <Link style={{ color: "black" }}>A5. Tour/On Duty</Link>
                </div>
              </span>
              <span>{monthlyReport?.a5}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA6")}>
                  <Link style={{ color: "black" }}>A6. Audit</Link>
                </div>
              </span>
              <span>{monthlyReport?.a6}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA7")}>
                  <Link style={{ color: "black" }}>A7. Details on TSMS</Link>
                </div>
              </span>
              <span>{monthlyReport?.a11TSMS}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA8")}>
                  <Link style={{ color: "black" }}>A8. Budget</Link>
                </div>
              </span>
              <span>{monthlyReport?.a7}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA9")}>
                  <Link style={{ color: "black" }}>A9.Vehicles Info</Link>
                </div>
              </span>
              <span>{monthlyReport?.a8}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA10")}>
                  <Link style={{ color: "black" }}>A10. Legal cases</Link>
                </div>
              </span>
              <span>{monthlyReport?.a9}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArA11")}>
                  <Link style={{ color: "black" }}>A11. Meetings</Link>
                </div>
              </span>
              <span>{monthlyReport?.a10}</span>
            </div>
          </div>
        </div>

        <div className="box2 rounded p-2">
          <div className="box2_inner1 rounded mb-2 p-box set-max-height">
            <h4 className="cl-g"> B. Education Activities </h4>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB1")}>
                  <a>B1. Students Strength</a>
                </div>
              </span>
              <span>{monthlyReport?.b1}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB2")}>
                  <a>B2. Courses Handled</a>
                </div>
              </span>
              <span>{monthlyReport?.b06CourseHandled}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB3")}>
                  <Link style={{ color: "black" }}>B3. Additional Effort</Link>
                </div>
              </span>
              <span>{monthlyReport?.b07EffortTaken}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB4")}>
                  <Link style={{ color: "black" }}>
                    B4. G L Organized
                    {/* (Fellowships/Awards/Rank only): Nil */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b08GuestLecture}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB5")}>
                  <Link style={{ color: "black" }}>
                    B5. Exposure Visit
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b09ExposureVisit}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB6")}>
                  <Link style={{ color: "black" }}>
                    B6. Student Guidance
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b10StudentGuidance}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB7")}>
                  <Link style={{ color: "black" }}>
                    B7. A C Meetings
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b11AdvisoryCommittee}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB8")}>
                  <Link style={{ color: "black" }}>
                    B8. Teaching Activities
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b12TeachingActivities}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB9")}>
                  <Link style={{ color: "black" }}>
                    B9. Stu Facilitation
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b2}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB10")}>
                  <Link style={{ color: "black" }}>
                    B10. Placement Info
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b3}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB11")}>
                  <Link style={{ color: "black" }}>
                    B11. Achievements-A
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b4}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArB12")}>
                  <Link style={{ color: "black" }}>
                    B12. Achievements-EC
                    {/* (Prizes won at Intercollegiate/State/National level): Nil{" "} */}
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b5}</span>
            </div>

            {/* <div className="number">
              <span>
                
                <div onClick={() => NextPage("/ArB13")}>
                  <Link style={{ color: "black" }}>
                B13. Controllerate of Examination Activities 
                   
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.b5}</span>
            </div> */}
            {/* <div className="box3_inner1 rounded mb-2 pc-box">
              <h4 className="cl-r">C. Research</h4>

              <div className="number">
                <span>
                  <div onClick={() => NextPage("/ArC1")}>
                    <Link style={{ color: "black" }}>C1. Crop Varieties</Link>
                  </div>
                </span>
                <span>{monthlyReport?.c01}</span>
              </div>
            </div> */}
          </div>
        </div>

        <div className="box3 rounded p-2">
          <div className="box3_inner1 rounded mb-2 p-box set-max-height">
            <h4 className="cl-e">C. Research</h4>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC1")}>
                  <Link style={{ color: "black" }}>C1. Crop Varieties</Link>
                </div>
              </span>
              <span>{monthlyReport?.c01}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC2")}>
                  <Link style={{ color: "black" }}>C2. Technologies</Link>
                </div>
              </span>
              <span>{monthlyReport?.c02}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC3")}>
                  <Link style={{ color: "black" }}>C3. New Implementation</Link>
                </div>
              </span>
              <span>{monthlyReport?.c03}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC4")}>
                  <Link style={{ color: "black" }}>C4. University R P</Link>
                </div>
              </span>
              <span>{monthlyReport?.c04}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC5")}>
                  <Link style={{ color: "black" }}>C5. AICRP Projects</Link>
                </div>
              </span>
              <span>{monthlyReport?.c05}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC6")}>
                  <Link style={{ color: "black" }}>C6. VC Projects</Link>
                </div>
              </span>
              <span>{monthlyReport?.c06}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC7")}>
                  <Link style={{ color: "black" }}>
                    C7. Revolving Fund Projects
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c07}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC8")}>
                  <Link style={{ color: "black" }}>
                    C8. External Fund Projects
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c08}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC9")}>
                  <Link style={{ color: "black" }}>
                    C9. Bio-Efficacy Projects
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c09}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC10")}>
                  <Link style={{ color: "black" }}>
                    C10. Germplasm Collection
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c10}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC11")}>
                  <Link style={{ color: "black" }}>C11. Registration</Link>
                </div>
              </span>
              <span>{monthlyReport?.c11}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC12")}>
                  <Link style={{ color: "black" }}>
                    C12. Linkages established
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c12}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC13")}>
                  <Link style={{ color: "black" }}>
                    C13. MLT/OFT/ Action Plan
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c13}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC14")}>
                  <Link style={{ color: "black" }}>
                    C14. New Project Proposed
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.c14}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC15")}>
                  <Link style={{ color: "black" }}>C15. Project Closure</Link>
                </div>
              </span>
              <span>{monthlyReport?.c15}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArC16")}>
                  <Link style={{ color: "black" }}>C16. Editorial Service</Link>
                </div>
              </span>
              <span>{monthlyReport?.c16}</span>
            </div>
          </div>

          {/* <div className="box3_inner1 rounded mb-2 p-box">
            <h4 className="cl-a">Publications</h4>
           


          </div> */}
        </div>

        <div className="box4 rounded p-2">
          <div className="box4_inner2 rounded p-box set-max-height-d">
            <h4 className="cl-g">D. Publications</h4>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD1")}>
                  <Link style={{ color: "black" }}>D1. Journals</Link>
                </div>
              </span>
              <span>{monthlyReport?.d01}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD2")}>
                  <Link style={{ color: "black" }}>D2. Books/Chapter</Link>
                </div>
              </span>
              <span>{monthlyReport?.d02}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD3")}>
                  <Link style={{ color: "black" }}>D3. Papers</Link>
                </div>
              </span>
              <span>{monthlyReport?.d03}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD4")}>
                  <Link style={{ color: "black" }}>D4. Popular articles</Link>
                </div>
              </span>
              <span>{monthlyReport?.d04}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD5")}>
                  <Link style={{ color: "black" }}>D5. C/S/W attender</Link>
                </div>
              </span>
              <span>{monthlyReport?.d05}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD6")}>
                  <Link style={{ color: "black" }}>D6. C/S/W organized</Link>
                </div>
              </span>
              <span>{monthlyReport?.d06}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArD7")}>
                  <Link style={{ color: "black" }}>D7. Lecture deliveries</Link>
                </div>
              </span>
              <span>{monthlyReport?.d07}</span>
            </div>
          </div>

          <div className="box4_inner2 rounded p-box pf-box set-max-height-d">
            <h4 className="cl-e">E. Extension Activities</h4>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE1")}>
                  <Link style={{ color: "black" }}>E1. Training Organized</Link>
                </div>
              </span>
              <span>{monthlyReport?.e01}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE2")}>
                  <Link style={{ color: "black" }}>E2. OFT/FLD/FS/FFS</Link>
                </div>
              </span>
              <span>{monthlyReport?.e02}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE3")}>
                  <Link style={{ color: "black" }}>E3. Training Attended</Link>
                </div>
              </span>
              <span>{monthlyReport?.e03}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE4")}>
                  <Link style={{ color: "black" }}>E4. Diagnostic F V</Link>
                </div>
              </span>
              <span>{monthlyReport?.e04}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE5")}>
                  <Link style={{ color: "black" }}>
                    E5. Extension activities
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.e05}</span>
            </div>
          </div>
        </div>

        <div className="box5 rounded p-2">
          <div className="box4_inner2 rounded p-box  set-max-height-e">
            <h4 className="cl-e">E. Extension Activities</h4>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE6")}>
                  <Link style={{ color: "black" }}>
                    E6. KAVIADP/ other service
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.e06}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE7")}>
                  <Link style={{ color: "black" }}>E7. FPO</Link>
                </div>
              </span>
              <span>{monthlyReport?.e07}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE8")}>
                  <Link style={{ color: "black" }}>E8. SHG</Link>
                </div>
              </span>
              <span>{monthlyReport?.e08}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArE9")}>
                  <Link style={{ color: "black" }}>E9. Policy Document</Link>
                </div>
              </span>
              <span>{monthlyReport?.e09}</span>
            </div>
          </div>
          <div className="box4_inner2 rounded pf-box  set-max-height-f">
            <h4 className="cl-g">F. Institutional Development</h4>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF1")}>
                  <Link style={{ color: "black" }}>F1. P/D of Seeds</Link>
                </div>
              </span>
              <span>{monthlyReport?.f01}</span>
            </div>
            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF2")}>
                  <Link style={{ color: "black" }}>F2. Income</Link>
                </div>
              </span>
              <span>{monthlyReport?.f02}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF3")}>
                  <Link style={{ color: "black" }}>F3. Academic Respons..</Link>
                </div>
              </span>
              <span>{monthlyReport?.f03}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF4")}>
                  <Link style={{ color: "black" }}>
                    F4. Additional Respons..
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.f04}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF5")}>
                  <Link style={{ color: "black" }}>F5. Imp Visitors</Link>
                </div>
              </span>
              <span>{monthlyReport?.f05}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF6")}>
                  <Link style={{ color: "black" }}>F6. Equipment Purchase</Link>
                </div>
              </span>
              <span>{monthlyReport?.f06}</span>
            </div>

            <div className="number">
              <span>
                <div onClick={() => NextPage("/ArF7")}>
                  <Link style={{ color: "black" }}>
                    F7. Award / Recognition
                  </Link>
                </div>
              </span>
              <span>{monthlyReport?.f07}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default GeneralAR;
